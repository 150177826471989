import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "protected-accounts"
    }}>{`Protected accounts`}</h1>
    <p>{`Protected accounts are accounts that are under backup protection.`}</p>
    <h2 {...{
      "id": "add-new-protected-account-aws"
    }}>{`Add new protected account (AWS)`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Accounts" from the navigation bar.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_1.png")} /></li>
      <li parentName="ol">{`Click on "Protect new account" button.`}</li>
      <li parentName="ol">{`Choose AWS from account cloud provider list.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_2.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Account Name`}</inlineCode>{` and `}<inlineCode parentName="li">{`AWS Account`}</inlineCode>{` number and click on "Create policy document" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_aws_1.png")} /></li>
      <li parentName="ol">{`Click on "Download Role Definition" button. The CloudFormation template you've downloaded needs to be deployed on your AWS account to complete onboarding.
Now you can click the "Finish Account Onboarding" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_aws_2.png")} /></li>
      <li parentName="ol">{`Congratulations, your account has been added.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_3.png")} /></li>
    </ol>
    <h2 {...{
      "id": "add-new-protected-account-aws-china"
    }}>{`Add new protected account (AWS China)`}</h2>
    <p>{`The process of adding new protected account located in China is extended by an additional step of providing access keys to the IAM user.
This user will be used to assume the role, defined by the downloaded CloudFormation template. Therefore, the minimal permissions
associated with him should allow assuming the role.`}</p>
    <ol>
      <li parentName="ol">{`Choose "Accounts" from the navigation bar.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_1.png")} /></li>
      <li parentName="ol">{`Click on "Protect new account" button.`}</li>
      <li parentName="ol">{`Choose AWS China from account cloud provider list.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_2.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Account Name`}</inlineCode>{` and `}<inlineCode parentName="li">{`AWS Account`}</inlineCode>{` number and click on "Generate policy document" button to go to the next step.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_aws_1.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Access Key Id`}</inlineCode>{` and `}<inlineCode parentName="li">{`Secret Access Key`}</inlineCode>{` and click on "Generate policy document" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_aws_china_secrets.png")} /></li>
      <li parentName="ol">{`Now you should see download role definition option. Click on "Download Role Definition" button. The CloudFormation template you've downloaded needs to be deployed on your AWS account to complete onboarding.
Now you can click the "Finish Account Onboarding" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_aws_china_secrets_2.png")} /></li>
      <li parentName="ol">{`Congratulations, your account has been added.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_3.png")} /></li>
    </ol>
    <h2 {...{
      "id": "add-new-protected-account-azure"
    }}>{`Add new protected account (Azure)`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Accounts" from the navigation bar.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_1.png")} /></li>
      <li parentName="ol">{`Click on "Protect new account" button.`}</li>
      <li parentName="ol">{`Choose Azure from account cloud provider list.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_2.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Account Name`}</inlineCode>{` and `}<inlineCode parentName="li">{`Subscription Id`}</inlineCode>{` number and click on "Next - Provide credentials" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_azure.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Client Id`}</inlineCode>{`, `}<inlineCode parentName="li">{`Client secret`}</inlineCode>{`, and `}<inlineCode parentName="li">{`Tenant Id`}</inlineCode>{` and click the "Finish Account Onboarding" button.
Client Id, Client secret and Tenant Id can be obtained, by creating a service principal with the `}<strong parentName="li">{`Contributor`}</strong>{` role:
`}<a parentName="li" {...{
          "href": "https://docs.microsoft.com/en-us/cli/azure/create-an-azure-service-principal-azure-cli?view=azure-cli-latest"
        }}>{`Create an Azure service principal`}</a>
        <img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_azure_2.png")} /></li>
      <li parentName="ol">{`Congratulations, your account has been added.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_3.png")} /></li>
    </ol>
    <h1 {...{
      "id": "vault-accounts"
    }}>{`Vault accounts`}</h1>
    <p>{`Vault account is used only for storing backup replicas. Resources contained within your vault accounts won’t be backed up.`}</p>
    <h2 {...{
      "id": "add-new-vault-account-aws"
    }}>{`Add new vault account (AWS)`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Accounts" from the navigation bar.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_1.png")} /></li>
      <li parentName="ol">{`Click on "Add Vault Account" button.`}</li>
      <li parentName="ol">{`Choose AWS from account cloud provider list.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_vault_1.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Account Name`}</inlineCode>{` and `}<inlineCode parentName="li">{`AWS Account`}</inlineCode>{` number and click on "Create policy document" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_vault_aws_1.png")} /></li>
      <li parentName="ol">{`Click on "Download Role Definition" button. The CloudFormation template you've downloaded needs to be deployed on your AWS account to complete onboarding.
Now you can click the "Finish Account Onboarding" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_aws_2.png")} /></li>
      <li parentName="ol">{`Congratulations, your account has been added.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_3.png")} /></li>
    </ol>
    <h2 {...{
      "id": "add-new-vault-account-aws-china"
    }}>{`Add new vault account (AWS China)`}</h2>
    <p>{`The process of adding new vault account located in China is extended by an additional step of providing access keys to the IAM user.
This user will be used to assume the role, defined by the downloaded CloudFormation template. Therefore, the minimal permissions
associated with him should allow assuming the role.`}</p>
    <ol>
      <li parentName="ol">{`Choose "Accounts" from the navigation bar.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_1.png")} /></li>
      <li parentName="ol">{`Click on "Add Vault Account" button.`}</li>
      <li parentName="ol">{`Choose AWS China from account cloud provider list.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_vault_1.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Account Name`}</inlineCode>{` and `}<inlineCode parentName="li">{`AWS Account`}</inlineCode>{` number and click on "Generate policy document" button to go to the next step.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_vault_aws_1.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Access Key Id`}</inlineCode>{` and `}<inlineCode parentName="li">{`Secret Access Key`}</inlineCode>{` and click on "Generate policy document" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_aws_china_secrets.png")} /></li>
      <li parentName="ol">{`Now you should see download role definition option. Click on "Download Role Definition" button. The CloudFormation template you've downloaded needs to be deployed on your AWS account to complete onboarding.
Now you can click the "Finish Account Onboarding" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_aws_china_secrets_2.png")} /></li>
      <li parentName="ol">{`Congratulations, your account has been added.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_3.png")} /></li>
    </ol>
    <h2 {...{
      "id": "add-new-protectedvault-account-gcp"
    }}>{`Add new protected/vault account (GCP)`}</h2>
    <p>{`GCP Onboarding requirements:`}</p>
    <ul>
      <li parentName="ul">{`A storage bucket in protected accounts (regional with standard storage class in Europe) with the label `}<inlineCode parentName="li">{`nordcloud-purpose: scanner`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`A Service Account`}</li>
    </ul>
    <p>{`This service account will need to be granted the following roles on the following scopes:`}</p>
    <ul>
      <li parentName="ul">{`Resource: The service account itself (with enabled IAM Service Account Credentials API)`}<ul parentName="li">
          <li parentName="ul">{`roles/iam.serviceAccountTokenCreator (Service Account Token Creator)`}</li>
        </ul></li>
      <li parentName="ul">{`Project:  On Vault projects (with enabled Compute Engine API)`}<ul parentName="li">
          <li parentName="ul">{`roles/compute.storageAdmin (Storage Admin)`}</li>
        </ul></li>
      <li parentName="ul">{`Project: On Protected projects (with enabled Compute Engine API)`}<ul parentName="li">
          <li parentName="ul">{`roles/compute.storageAdmin (Storage Admin)`}</li>
          <li parentName="ul">{`roles/serviceusage.serviceUsageAdmin (Service Usage Admin)`}</li>
          <li parentName="ul">{`roles/cloudasset.viewer (Cloud Asset Viewer)`}</li>
          <li parentName="ul">{`roles/viewer (Viewer)`}</li>
        </ul></li>
      <li parentName="ul">{`Bucket:`}<ul parentName="li">
          <li parentName="ul">{`roles/storage.objectCreator (Storage Object Creator)`}</li>
        </ul></li>
    </ul>
    <p>{`Alternatively, two custom roles (instead of roles/compute.storageAdmin) can be created for Vault and Protected project bindings.`}</p>
    <p><strong parentName="p">{`Vault Role`}</strong></p>
    <ul>
      <li parentName="ul">{`compute.disks.createSnapshot`}</li>
      <li parentName="ul">{`compute.globalOperations.get`}</li>
      <li parentName="ul">{`compute.images.create`}</li>
      <li parentName="ul">{`compute.images.delete`}</li>
      <li parentName="ul">{`compute.images.getIamPolicy`}</li>
      <li parentName="ul">{`compute.images.setIamPolicy`}</li>
      <li parentName="ul">{`compute.instances.get`}</li>
      <li parentName="ul">{`compute.instances.list`}</li>
      <li parentName="ul">{`compute.regionOperations.get`}</li>
      <li parentName="ul">{`compute.snapshots.create`}</li>
      <li parentName="ul">{`compute.snapshots.delete`}</li>
      <li parentName="ul">{`compute.snapshots.get`}</li>
      <li parentName="ul">{`compute.snapshots.setLabels`}</li>
      <li parentName="ul">{`compute.snapshots.useReadOnly`}</li>
      <li parentName="ul">{`compute.zoneOperations.get`}</li>
    </ul>
    <p><strong parentName="p">{`Protected Role`}</strong></p>
    <ul>
      <li parentName="ul">{`compute.disks.create`}</li>
      <li parentName="ul">{`compute.disks.createSnapshot`}</li>
      <li parentName="ul">{`compute.disks.delete`}</li>
      <li parentName="ul">{`compute.snapshots.create`}</li>
      <li parentName="ul">{`compute.snapshots.delete`}</li>
      <li parentName="ul">{`compute.snapshots.get`}</li>
      <li parentName="ul">{`compute.snapshots.setLabels`}</li>
      <li parentName="ul">{`compute.zoneOperations.get`}</li>
    </ul>
    <p>{`Consider the following regarding the principle of the least privilege.`}</p>
    <ol>
      <li parentName="ol">{`Updating the role. If the role is created on the organization level, the role only needs to be updated there, however you
will also have permission to update roles other custom roles on the org node. If you create the role on projected level, You
need to also be able to update all them when new features are launched.`}</li>
      <li parentName="ol">{`Using the predefined roles gives more permissions, but those permissions are still limited to project scope and related to
the purpose of AB.`}</li>
    </ol>
    <ol>
      <li parentName="ol">{`Choose "Accounts" from the navigation bar.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_1.png")} /></li>
      <li parentName="ol">{`Click "Protect New Account" or "Add Vault Account", depending on which type of account you want to onboard.`}</li>
      <li parentName="ol">{`Choose GCP from account cloud provider list.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_2.png")} />
        <img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_vault_1.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Account Name`}</inlineCode>{` and `}<inlineCode parentName="li">{`Project Id`}</inlineCode>{`. Proceed to next step by clicking `}<inlineCode parentName="li">{`Next - Provide credentials`}</inlineCode>{`.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_gcp_1.png")} /></li>
      <li parentName="ol">{`Upload credentials file clicking `}<inlineCode parentName="li">{`Choose file`}</inlineCode>{`. Or if you have already uploaded a keypair, select your service account from the dropdown list.
Now you can click the "Finish Account Onboarding" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_gcp_2.png")} /></li>
      <li parentName="ol">{`Congratulations, your account has been added.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_3.png")} /></li>
    </ol>
    <h2 {...{
      "id": "add-new-protected-account-ibm-cloud"
    }}>{`Add new protected account (IBM Cloud)`}</h2>
    <p>{`For onboarding account to AutoBackup you must provide the `}<a parentName="p" {...{
        "href": "https://cloud.ibm.com/docs/account?topic=account-serviceidapikeys&interface=ui#create_service_key"
      }}>{`API key`}</a>{`.`}</p>
    <p>{`Required permissions for scanning and backup process:`}</p>
    <ul>
      <li parentName="ul">{`is.instance.instance.read`}</li>
      <li parentName="ul">{`is.instance.instance.operate`}</li>
      <li parentName="ul">{`is.volume.volume.read`}</li>
      <li parentName="ul">{`is.volume.volume.operate`}</li>
      <li parentName="ul">{`is.snapshot.snapshot.read`}</li>
      <li parentName="ul">{`is.snapshot.snapshot.create`}</li>
      <li parentName="ul">{`is.snapshot.snapshot.delete`}</li>
      <li parentName="ul">{`global-search-tagging.tag.attach-user-tag`}</li>
      <li parentName="ul">{`global-search-tagging.resource.read`}</li>
      <li parentName="ul">{`resource-controller.instance.create`}</li>
    </ul>
    <ol>
      <li parentName="ol">{`Choose "Accounts" from the navigation bar.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_1.png")} /></li>
      <li parentName="ol">{`Click "Protect New Account".`}</li>
      <li parentName="ol">{`Choose IBM CLOUD from account cloud provider list.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_2.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`Account Name`}</inlineCode>{` and `}<inlineCode parentName="li">{`IBM Cloud Account Id`}</inlineCode>{`. Proceed to next step by clicking `}<inlineCode parentName="li">{`Next - Provide credentials`}</inlineCode>{`.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_ibm_cloud_1.png")} /></li>
      <li parentName="ol">{`Type your `}<inlineCode parentName="li">{`API key`}</inlineCode>{` and click the "Finish Account Onboarding" button.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_ibm_cloud_2.png")} /></li>
      <li parentName="ol">{`Congratulations, your account has been added.
`}<img alt="Accounts view" src={require("../../assets/configure/accounts/accounts_3.png")} /></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      